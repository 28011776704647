@use '@chicksgroup/styles/mixins' as m;
@use '@chicksgroup/styles/variables' as v-global;
@import 'styles/variables';

#faq {
    display: flex;
    justify-content: center;
    background-color: var(--cg-grey-100);
    padding-block: 65px 0px;

    /* 
        Collapse Icon transition
    */
    --cds-collapse-icon-rotate-duration: .60s;
    --cds-collapse-transition-timing-function: cubic-bezier(0.17, 0, 0.38, 0.99);
    --cds-collapse-transition-time: .60s;
    --cds-collapse-icon-rotation: -180deg;

    /* 
        Vars
    */
    --collapse-side-icon-size: 16px;
    --cds-primary-color-50: var(--cx-secondary-700);
    --cds-primary-color: var(--cx-secondary-700);
    --collapse-bg: var(--cg-grey-200);
    --collapse-bg-hover: var(--cx-primary-300);
    --collapse-question-answer-line-height: 20px;

    /* 
        Collapse Spacing
    */
    --cds-collapse-header-padding: #{v-global.$regularPad};
    --cds-collapse-header-gap: 16px;

    /* 
        Collapse Color (Category)
    */
    --cds-collapse-content-background-color: var(--cg-grey-100);
    --cds-collapse-open-icon-color: var(--cg-grey-900);
    --cds-collapse-background-color: var(--collapse-bg);
    --cds-collapse-background-color-hover: var(--collapse-bg-hover);
    --cds-collapse-background-color-active: var(--collapse-bg-hover);
    --cds-collapse-open-background-color: var(--collapse-bg-hover);

    /*
        Collapse Border
    */
    --cds-collapse-header-border-radius: 4px;
    --cds-collapse-header-outline: 1px solid transparent;

    /* 
        Collapse Font
    */
    --cds-question-collapse-line-clamp: 2;

    /* 
        Question Collapse Scrollbar
    */
    --cds-question-collapse-scrollbar-thumb-bg: var(--cx-secondary-700);

    /* 
        Searchbar Color
    */
    --md-filled-text-field-trailing-icon-color: var(--cg-grey-800);
    --cds-text-field-focus-floating-label-color: var(--cg-grey-600);
    --cds-text-field-icon-color: var(--cg-grey-500);

    --cds-input-background: var(--cg-grey-200);
    --cds-input-focus-background-color: var(--cg-grey-200);

    --md-filled-text-field-focus-label-text-color: var(--cg-grey-600);
    --md-filled-text-field-focus-label-text-color: var(--cg-grey-600);

    --md-filled-text-field-focus-caret-color: var(--cg-grey-500);
    --md-filled-text-field-focus-input-text-color: var(--cg-grey-700);

    --md-filled-text-field-caret-color: var(--cg-grey-500);
    --md-filled-text-field-leading-icon-color: var(--cg-grey-700);

    --md-filled-text-field-label-text-color: var(--cg-grey-600);

    --md-filled-text-field-hover-label-text-color: var(--cg-grey-700);
    --md-filled-text-field-hover-state-layer-color: var(--cg-grey-200);
    --md-filled-text-field-hover-input-text-color: var(--cg-grey-700);

    --md-filled-text-field-active-label-text-color: var(--cg-grey-600);

    --md-filled-text-field-container-color: var(--cg-grey-200);

    --md-filled-text-field-input-text-color: var(--cg-grey-700);
    --md-filled-text-field-input-text-placeholder-color: var(--cg-grey-600);

    /* 
        Searchbar Font
    */
    --md-filled-text-field-input-text-weight: 400;
    --md-filled-text-field-input-text-font: #{v-global.$primaryfont};
    --md-filled-text-field-input-text-size: 14px;

    /* 
        Searchbar Border
    */
    --md-filled-text-field-container-shape-end-end: 4px;
    --md-filled-text-field-container-shape-end-start: 4px;
    --md-filled-text-field-container-shape-start-end: 4px;
    --md-filled-text-field-container-shape-start-start: 4px;

    --cds-text-field-default-border: 1px solid transparent;

    /* 
        Searchbar Spacing
    */
    --cds-text-field-leading-icon-end-margin: 8px;
    --cds-text-field-leading-icon-start-margin: 16px;

    /* 
        Searchbar Size
    */
    --cds-text-field-width: 400px;

    /*
        Searchbar icon
    */
    --cds-text-field-trailing-icon-cursor: pointer;


    /* 
        Question Collapse Border
    */

    /* 
        Question Collapse Color
    */
    --cds-question-collapse-header-question-color: var(--cg-grey-1000);
    --cds-question-collapse-fade-start-color: rgba(245, 245, 247, 0.00);
    --cds-question-collapse-fade-end-color: rgb(245, 245, 247);
    --cds-question-collapse-background-color: rgba(245, 245, 247, 1);
    --cds-question-collapse-divider-background-color: rgba(0, 0, 0, 0.05);

    /* 
        Question Collapse Spacing
    */

    --cds-question-collapse-header-padding: unset;
    --cds-question-collapse-content-height: calc(var(--collapse-question-answer-line-height) * 20);
    --cds-question-collapse-fade-height: calc(#{v-global.$medium} * 3);

    /* 
        Question Collapse Font 
    */
    --cds-question-collapse-header-question-font-family: #{v-global.$primaryfont};
    --cds-question-collapse-header-question-font-size: 16px;
    --cds-question-collapse-header-question-line-height: 20px;
    --cds-question-collapse-header-question-letter-spacing: 0.04px;
    --cds-question-collapse-header-question-font-weight: 500;

    @media(width <=$tablet) {
        padding-block: 60px 0px;
        --cds-question-collapse-line-clamp: 4;
        --cds-question-collapse-content-height: calc(var(--collapse-question-answer-line-height) * 13);
    }

    @media(width <=$phone) {
        --cds-question-collapse-content-height: calc(var(--collapse-question-answer-line-height) * 28);
    }

    .skeleton-loading {
        background-color: $ghost-white !important;

        &.skeleton-title {
            width: 50px;
            position: relative;
            left: 20px;
        }

        @media(width: 800px) {
            left: 0px;
        }
    }

    &.skeleton-policy {
        .container-question-section {
            border: 0 !important;
        }
    }

    .no-results {

        .no-results-message {
            line-height: 20px;
            letter-spacing: 0.04px;
        }

        min-width: 490px;
        --cds-large-button-arrow-color: transparent;
        --cds-large-button-padding-inline: 0px;
        --cds-large-button-padding-block: 0px;
        --cds-large-button-description-font-weight: 300;
        --cds-large-button-box-min-height: 40px;
        --cds-large-button-description-font-size: #{v-global.$medium};
        --cds-large-button-truncate-lines: none;
        --cds-large-button-description-line-height: 20px;
        --cds-large-button-description-color: var(--cg-grey-900);
        --cds-large-button-box-padding-inline: 0px;
        --cds-large-button-box-padding-block: 0px;
        --cds-large-button-default-background: transparent;
        --cds-large-button-hover-background: transparent;
        --cds-large-button-title-color: var(--cg-grey-900);
        --cds-large-button-icon-color: var(--cg-grey-700);

        @media(width <=$tablet) {
            min-width: 90px;
        }
    }

    .skeleton-loading {

        &.container-heading-text {
            height: 50px;
            width: 80px;
        }
    }

    .container-section-icon {
        --md-icon-size: 32px;
        box-sizing: border-box;
        max-width: 32px;
        max-height: 32px;
        padding: 2px;
    }

    cds-collapse {
        --md-icon-size: var(--collapse-side-icon-size);
    }

    cds-search {
        --md-icon-size: 24px;
        --cds-text-field-leading-icon-size: 24px;
        --cds-text-field-trailing-icon-size: 12px;
        transition: border .15s ease;
        box-sizing: border-box;

        &:focus {
            --cds-text-field-default-border: 3px solid transparent;
        }

        &.trailing-icon {
            --md-icon-color: transparent;
            --cds-text-field-leading-icon-size: 16px;

        }
    }

    .container-search {
        --cds-drawer-z-index: 10000;
        --cds-drawer-position: fixed;

        /*
            Close Button Color
        */
        --md-text-button-label-text-weight: 500;
        --md-text-button-icon-color: var(--cx-secondary-700);
        --cds-button-max-width: fit-content;
        --cds-button-width: fit-content;
        --md-text-button-hover-icon-color: var(--cx-secondary-700);
        --md-text-button-pressed-icon-color: var(--cx-secondary-700);

        --cds-drawer-header-close-button-text-color: var(--cx-secondary-700);
        --cds-drawer-header-close-button-hover-text-color: var(--cx-secondary-700);
        --cds-drawer-header-close-button-focus-text-color: var(--cx-secondary-700);
        --cds-drawer-header-close-button-pressed-text-color: var(--cx-secondary-700);

        --md-filled-icon-button-icon-color: var(--cg-grey-100);

        /*
            Close Button Font
        */
        --cds-drawer-header-close-button-text-size: 16px;
        --cds-drawer-header-close-button-hover-text-size: 24px;
        --cds-drawer-header-close-button-text-weight: 500;
        /*
            Close Button Icon Size
        */
        --cds-drawer-close-button-icon-size: 16px;

        /* 
            Open Button Color
        */
        --md-filled-icon-button-container-color: var(--cx-secondary-700);
        --md-filled-icon-button-hover-state-layer-color: var(--cx-secondary-800);
        --md-filled-icon-button-hover-state-layer-opacity: var(--cx-secondary-800);
        --md-filled-icon-button-pressed-state-layer-color: var(--cx-secondary-900);
        --md-filled-icon-button-pressed-state-layer-opacity: var(--cx-secondary-900);

        /* 
            Open Button Size
        */
        --md-filled-icon-button-container-width: 100%;
        --md-filled-icon-button-container-height: 52px;
        --md-filled-icon-button-container-shape: 4px;

        /* 
            Drawer Color
        */
        --cds-drawer-divider-color: rgba(240, 240, 240, 1);

        /* 
            Drawer Spacing
        */
        --cds-drawer-header-padding: 12px 20px;
        --md-text-button-label-text-color: var(--cx-secondary-700);
        --cds-drawer-header-space: 16px;
        --cds-drawer-header-close-button-width: 92px;
        --cds-drawer-header-close-button-trailing-space: 0px;

        /* 
            Drawer color
        */
        --cds-drawer-bg: var(--cg-grey-100);
    }

    @media (width <=$tablet) {
        --cds-collapse-header-padding: #{v-global.$regularPad};
    }

    .line-clamp {
        @include m.truncate-text(4);

        @media (width > $tablet) {
            -webkit-line-clamp: 2;
        }
    }

    .hide-line-clamp {
        @include m.truncate-text(4);
        overflow: visible;
    }

    .drawer-content {
        max-height: calc(100vh - 70px);
    }

    .container-header {
        max-height: 52px;
        @include m.flex(center, space-between);

        .container-heading-text {
            @include m.font($moreplushuge, 700, 36px, $color: var(--cg-grey-900));

            @media (width <=$tablet) {
                @include m.font($extralarge, $height: 28px);
            }
        }
    }

    .divider-mobile {
        height: 1px;
        opacity: 100%;
        background-color: $color-grey-300;
        margin: 0;
        display: none;
        border: none;
        margin-top: $regularPad;
        margin-bottom: $bigPad;

        @media (width <=$tablet) {
            display: block;
        }
    }


    .question-card-border-container {
        height: 100%;
        border: 1px solid var(--cg-grey-400);
        overflow: hidden;
        border-top: none;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .question-card-border {
        border: 1px solid var(--cg-grey-400);
        border-top: none;
        border-right: none;
        border-left: none;
    }

    .hover-whole-question {

        &:active {
            --cds-collapse-open-background-color-hover: var(--collapse-question-hover);
            --cds-collapse-open-background-color-active: var(--collapse-bg-hover);
        }

        &:hover {
            --cds-collapse-content-background-color: var(--collapse-question-hover);
            --cds-collapse-open-background-color: var(--collapse-question-hover);
            --cds-question-collapse-content-background-color: var(--collapse-question-hover);
            --cds-collapse-open-background-color-active: var(--collapse-question-hover);
            --cds-collapse-background-color: var(--collapse-question-hover);
        }
    }

    .question-card {
        /*
            Transition
        */
        --cds-collapse-open-header-border-radius: 0;
        --cds-collapse-open-padding-transition: .25s;

        /* 
            Spacing
        */
        --cds-question-collapse-header-question-color: var(--cg-grey-900);
        --cds-collapse-open-padding: 26px #{$regularPad} 8px;
        --cds-collapse-header-padding: 26px #{$largeplus};

        /* 
            Border
        */
        --cds-collapse-header-border-radius: 0px;
        --cds-collapse-header-outline-container: 1px solid transparent;
        --cds-collapse-header-outline: 1px solid transparent;

        /* 
            Color 
        */
        --cds-collapse-closed-background-color-active: var(--collapse-question-hover);
        --cds-collapse-background-color: var(--cg-grey-100);
        --cds-collapse-hover: var(--collapse-question-hover);
        --collapse-question-hover: #{$primary-200};
        --cds-question-collapse-content-background-color: var(--cg-grey-100);
        --cds-collapse-background-color-hover: var(--collapse-question-hover);
        --cds-collapse-background-color-active: var(--collapse-bg-hover);
        --cds-collapse-open-background-color: var(--cg-grey-100);
        --cds-collapse-open-background-color-hover: var(--collapse-question-hover);
        --cds-collapse-open-background-color-active: var(--collapse-question-hover);

        /*

            Icon size
        */
        --md-icon-color: rgba(31, 31, 31, 1);
        --cds-icon-color: rgba(31, 31, 31, 1);
        --md-icon-size: var(--collapse-side-icon-size);

        /*
            Font
        */
        --cds-question-collapse-open-line-clamp: 2;

        @media(width <=$tablet) {
            --cds-question-collapse-open-line-clamp: 4;
        }

        .question-content {
            @include m.font($size: $regular, $weight: 400, $height: 20px, $spacing: 0.07px, $color: var(--cg-grey-700));
            font-family: v-global.$primaryfont !important;
            word-break: break-word;
            padding: 0 v-global.$regularPad v-global.$regularPad;
            margin: 0 !important;
        }

    }

    .reduced-duration-animation {
        --cds-collapse-transition-time: .60s;
    }

    .extra-duration-animation {
        --cds-collapse-transition-time: .78s;
    }

    .container-question-section {
        border: 1px solid $color-grey-400;
        display: flex;
        border-radius: 4px;
        gap: v-global.$regularPad;
        padding: v-global.$regularPad;

        @media (width <=$tablet) {
            border: none;
            padding: 0px;
            flex-wrap: wrap;
        }
    }

    .section-header {
        word-break: break-all;
        @include m.font($size: v-global.$medium, $weight: 600, $height: 24px, $spacing: 0px, $color: var(--cg-grey-900));
        font-family: v-global.$primaryfont;
        text-align: left;
        margin: 0;
        display: -webkit-box;
    }

    .search-bar {
        display: block;

        @media(width <=$tablet) {
            --cds-text-field-width: 252px;
        }

        @media (width <=$phone) {
            display: none;
        }
    }

    .questions-container {
        display: flex;
        flex-direction: column;
        gap: 16px
    }

    .collapsible-divider {
        background-color: $collapsible-divider;
        height: 1px;
        flex-grow: 0.8;
    }

    .section-column {

        flex-direction: column;
        gap: $regularPad;
        width: 100%;
    }

    .category-card-image {
        @include m.square(100%);
    }

    .container-section-icon {
        @include m.flex-center();
        flex-shrink: 0;
        padding: 2px 0px;
        @include m.square(32px);
    }

    .search-bar,
    .drawer-search {
        max-height: 52px;
        --cds-text-field-height: 18.1px;
        --md-filled-text-field-with-label-top-space: 10px;

        &:focus {
            --cds-icon-color: var(--cg-grey-700);
        }

        --cds-icon-color: var(--cg-grey-700);

        .search-mobile &cds-drawer {
            --cds-icon-color: var(--cg-grey-700);
        }
    }

    .search-bar,
    .drawer-search {
        &md-icon {
            font-weight: 700;
        }
    }

    .drawer-search {
        max-height: 52px;
        width: 100%;
    }

    .search-mobile {

        &cds-drawer {
            --md-icon-size: 24px;
        }

        display: none;
        @include m.size(72px, 52px);

        @media (width <=$phone) {
            --cds-text-field-width: 100%;
            display: block;
        }
    }

    .section-column-mobile {
        --md-icon-size: 16px;
        @include m.flex-gap(v-global.$regularPad, $direction: column);
        padding: v-global.$regularPad;

    }

    @media (width <=$tablet) {
        --cds-text-field-width: 252px;
    }

    .faq-container {
        @include m.flex-gap(v-global.$bigPad, $direction: column);
        padding: 0;
        width: 1560px;
        margin: v-global.$hugePad v-global.$regularPad;

        @media(width <=$tablet) {
            @include m.flex-gap(0px, $direction: column);
            margin: v-global.$largePad v-global.$regularPad !important;
        }

        @media(width <=$phone) {
            @include m.flex-gap(0px, $direction: column);
            margin: v-global.$bigPad v-global.$regularPad !important;
        }
    }
}